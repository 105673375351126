* {
	box-sizing: border-box;
}

html {
	height: auto;
}

body {
	height: auto;
	color: #363636;
	font-family: 'Chivo', Arial, Helvetica, sans-serif;
	margin: 0;
	padding-right: 15px;
	background-color: #e8e8e8;

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body.noScrolling {
	height: 100vh;
	overflow-y: hidden;
}

#root {
	width: 100%;
}

main {
	padding: 2em;
}

/* typography */

.main__title {
	color: #363636;
	text-align: center;
}

.playlist__name {
	font-size: 1.4rem;
	font-weight: 700;
	line-height: 1.1;
}

/* colours */
.colour1 {
	background-color: #736002;
}

.colour2 {
	background-color: #f0a967;
}

.colour3 {
	background-color: #e87f54;
}

.colour4 {
	background-color: #df5441;
}

/* blanking layer */
.blankingLayer {
	position: fixed;
	width: 100%;
	visibility: hidden;
	opacity: 0;

	transition-timing-function: linear;
	transition-duration: 300ms;
	transition-property: opacity;

	background-color: rgba(0, 0, 0, 0.95);
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
}

.blankingLayer.open {
	visibility: visible;
	opacity: 1;
}

/* loading layer */
.loading {
	position: fixed;
	width: 100%;
	display: none;
	background-color: rgba(20, 20, 20, 0.3);
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
}

.loading.loading--loading {
	display: flex;
	justify-content: center;
	align-items: center;
}

.loading__text {
	color: #fff;
}

/* playlists */

.playlists {
	display: grid;
	row-gap: 1em;
	column-gap: 1em;
	align-items: start;
	grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}

.playlists.open {
	height: 100%;
}

.playlists__btn {
	height: auto;
	color: #e8e8e8;
	min-height: 230px;

	padding: 1em;
	border-radius: 10px;
	border: 2px solid #787878;
}

.playlists__btn:hover,
.playlists__btn:focus,
.playlists__btn.selected {
	background-color: #ecc9a8;
	color: #363636;
}

.playlists__btn .playlist__description {
	font-size: 1rem;
	line-height: 1.2;

	max-height: 30px;
	transition-duration: 200ms;
	transition-property: max-height;
	transition-timing-function: linear;

	/*add ellipse*/
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.playlists__btn .playlist__description:hover {
	white-space: initial;
	overflow: initial;
	text-overflow: initial;
	max-height: 240px;
}

/* tracklist */
.tracklist__info {
	font-size: 0.875rem;
	font-weight: 300;
	color: #fff;
}

.tracklist__title {
	margin: 0.5em 0;
}

.tracklist__stats {
	margin: 0.2em 0;
}

.tracklist__description {
	margin-bottom: 0;

	color: #fff;
}

.tracklist__show_description {
	color: #60f205;
	font-weight: 700;
	margin: 0;
}

.tracklist__show_description:hover {
	color: #05f270;
}

.tracklist__description-text {
	display: none;
	margin: 5px;
	overflow-y: auto;
	max-height: 200px;
	color: #c0eea3;
}

.tracklist__description-text.showText {
	display: block;
}

.btn--close-tracklist {
	position: absolute;
	right: 0.5em;
	top: 0.5em;
}

.tracklist {
	display: flex;
	flex-direction: column;
	position: fixed;
	width: 100%;
	visibility: hidden;

	padding: 1.5em 2em;

	transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
	transition-duration: 0.5s;
	transition-property: transform;

	top: 0;
	left: 0;
	bottom: 0;
	transform: translateX(100%);
}

.tracklist.open {
	transform: translateX(0);
	visibility: visible;
}

.tracklist__tracks {
	overflow-y: auto;
}

/* trackItem */
.trackDetails {
	color: #fff;
	font-size: 1.2rem;
	margin-right: 0.5em;
}

.artistName {
	font-weight: 700;
}

.trackName {
	font-weight: 700;
	color: #f27405;
}

.albumName {
	color: #f2b705;
}

/*pagination*/
.pagination {
	margin: 0;
}

.pagination__pagecount {
	color: #fff;
}

button {
	border-radius: 5px;

	padding: 0.125em 0.5em;
	cursor: pointer;
	font-size: 1.2rem;
	color: rgb(20, 20, 20);
	padding: 0.1em 0.5em;
	border-radius: 5px;
}

.pagination button {
	margin-right: 0.2em;
	font-size: 0.9rem;
}

button:hover {
	background-color: rgba(200, 200, 200, 0.95);
}

button:disabled {
	background-color: rgba(100, 100, 100, 0.3);
	color: rgb(20, 20, 20);
	cursor: not-allowed;
}

button.currentPage {
	border: #f25c05 solid 2px;
	background-color: rgba(100, 100, 100, 0.3);
	color: #fff;
}
